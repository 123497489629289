import React from "react"
import tw from "twin.macro"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { graphql, useStaticQuery } from "gatsby"
import { useWindowSize } from "./utils"
import PropTypes from "prop-types"

// This wrapper allows easy access to the tw prop from
// outside the component for potential custom styles
const HeroWrapper = tw.section`relative text-white mb-[100px] pt-8 pb-12 overflow-x-clip lg:(pt-16 pb-10 mb-[125px]) xl:(pt-24 pb-14 mb-[150px])`

const Hero = ({ children, styles }) => {
  const data = useStaticQuery(graphql`
    query {
      bg: file(relativePath: { eq: "backgrounds/hero-background.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            width: 1920
            formats: [AUTO, WEBP, AVIF]
            placeholder: NONE
            quality: 90
          )
        }
      }
      bgMobile: file(
        relativePath: { eq: "backgrounds/hero-background-mobile.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(
            width: 750
            formats: [AUTO, WEBP, AVIF]
            placeholder: NONE
            quality: 90
          )
        }
      }
    }
  `)
  const { width } = useWindowSize()

  return (
    <HeroWrapper css={[styles && styles]}>
      <GatsbyImage
        image={width < 1024 ? getImage(data.bgMobile) : getImage(data.bg)}
        css={[tw`z-[-1] absolute top-0 left-0 w-full h-full`]}
        objectFit="cover"
        objectPosition="top center"
        alt=""
      />
      {children}
    </HeroWrapper>
  )
}

export default Hero

Hero.propTypes = {
  children: PropTypes.node.isRequired,
  styles: PropTypes.object,
}
