import React from "react"
import tw from "twin.macro"
import { graphql, useStaticQuery } from "gatsby"

import Seo from "../components/seo"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Container from "../components/container"
import Hero from "../components/hero"
import Layout from "../components/layout"
import Cta from "../components/cta"
import { useWindowSize } from "../components/utils"
import Button from "../components/button"

const WhatIsLivtencity = () => {
  const data = useStaticQuery(graphql`
    query {
      heroImage: file(relativePath: { eq: "what-is-livtencity/hero.png" }) {
        childImageSharp {
          gatsbyImageData(
            width: 720
            formats: [AUTO, WEBP, AVIF]
            placeholder: NONE
            quality: 90
          )
        }
      }
      smallPurpleBg: file(
        relativePath: { eq: "backgrounds/expand-content-small.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            width: 1024
            formats: [AUTO, WEBP, AVIF]
            placeholder: NONE
            quality: 90
          )
        }
      }
      largePurpleBg: file(
        relativePath: { eq: "backgrounds/expand-content-large.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            width: 1440
            formats: [AUTO, WEBP, AVIF]
            placeholder: NONE
            quality: 90
          )
        }
      }
      stat1_1: file(relativePath: { eq: "what-is-livtencity/stat-1-1.png" }) {
        childImageSharp {
          gatsbyImageData(
            width: 200
            formats: [AUTO, WEBP, AVIF]
            placeholder: NONE
            quality: 90
          )
        }
      }
      stat1_2: file(relativePath: { eq: "what-is-livtencity/stat-1-2.png" }) {
        childImageSharp {
          gatsbyImageData(
            width: 200
            formats: [AUTO, WEBP, AVIF]
            placeholder: NONE
            quality: 90
          )
        }
      }
      stat2_1: file(relativePath: { eq: "what-is-livtencity/stat-2-1.png" }) {
        childImageSharp {
          gatsbyImageData(
            width: 200
            formats: [AUTO, WEBP, AVIF]
            placeholder: NONE
            quality: 90
          )
        }
      }
      stat2_2: file(relativePath: { eq: "what-is-livtencity/stat-2-2.png" }) {
        childImageSharp {
          gatsbyImageData(
            width: 200
            formats: [AUTO, WEBP, AVIF]
            placeholder: NONE
            quality: 90
          )
        }
      }
      ctaImage: file(relativePath: { eq: "what-is-livtencity/cta.png" }) {
        childImageSharp {
          gatsbyImageData(
            width: 720
            formats: [AUTO, WEBP, AVIF]
            placeholder: NONE
            quality: 90
          )
        }
      }
      smallImage: file(relativePath: { eq: "backgrounds/cta-small-1.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            width: 1440
            formats: [AUTO, WEBP, AVIF]
            placeholder: NONE
            quality: 90
          )
        }
      }
      largeImage: file(relativePath: { eq: "backgrounds/cta-large-1.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            width: 1920
            formats: [AUTO, WEBP, AVIF]
            placeholder: NONE
            quality: 90
          )
        }
      }
    }
  `)
  const { width } = useWindowSize()

  return (
    <Layout activeLinkIndex={1}>
      <Hero styles={tw`mb-16 lg:(pt-24 pb-20 mb-20)`}>
        <Container>
          <div tw="grid grid-cols-4 gap-x-7 lg:(grid-cols-12)">
            <div tw="col-span-full lg:col-span-8">
              <h1 tw="uppercase font-light text-[32px] [line-height: 36px] mb-4 w-3/4 md:(text-43 mb-2 w-full) xl:(text-6xl mb-6 [line-height: 1.4])">
                What is <span tw="font-extrabold">LIVTENCITY</span>?
              </h1>
              <div tw="[shape-outside: polygon(90% 10%, 100% 0%, 100% 100%, 50% 100%)] float-right w-full h-[310px] lg:hidden" />
              <p tw="font-normal text-base w-full lg:(text-base pr-6 w-full) xl:(text-21 pr-20)">
                <span tw="font-bold">LIVTENCITY</span> is a prescription
                medicine used to treat CMV infection and disease in adults and
                children 12 years of age and older weighing at least 77 pounds
                (35 kg) who have received a transplant, when their infection or
                disease does not respond to treatment with the medicines
                ganciclovir, valganciclovir, cidofovir or foscarnet. It is not
                known if <span tw="font-bold">LIVTENCITY</span> is safe and
                effective in children under 12 years of age.
              </p>
            </div>
            <div tw="col-span-full lg:(relative col-span-4)">
              <GatsbyImage
                image={getImage(data.heroImage)}
                css={[
                  tw`absolute bottom-[-80px] right-[-80px] w-[311px] lg:(block w-[370px] right-[-30px] bottom-[unset] top-[-60px]) xl:(w-[450px] right-[0px])`,
                ]}
                alt=""
              />
            </div>
          </div>
        </Container>
      </Hero>

      <section tw="pb-96 lg:pb-48 xl:pb-64">
        <Container>
          <div tw="grid grid-cols-4 gap-x-7 text-base lg:(grid-cols-12) xl:text-21">
            <h2 tw="col-span-full uppercase inline-block w-10/12 mb-6 text-32 lg:(text-34 mb-4) xl:text-5xl [background-image: linear-gradient(123deg, #7C45FF 40%, #40016D 97%)] [background-clip: text] [-webkit-text-fill-color: transparent]">
              How Was <span tw="font-extrabold">LIVTENCITY Studied?</span>
            </h2>
            <div tw="font-light col-span-full mb-6 lg:(col-span-6 pr-4 mb-6) xl:pr-10">
              <p>
                <span tw="font-bold">The FDA approved LIVTENCITY</span> based on
                a study in adults with CMV infection after transplant (organ or
                HCT). CMV infections were refractory with or without confirmed
                resistance to one or more commonly used antiviral medicines*
                used in the study.{" "}
              </p>
              <p tw="text-[10px] [line-height: 14px] mt-4 lg:(text-[12px] [line-height: 18px] mt-2) xl:(text-base [line-height: 24px])">
                *The commonly used antiviral medicines were: ganciclovir,
                valganciclovir, cidofovir and foscarnet.
              </p>
            </div>
            <div tw="font-light col-span-full mb-8 lg:(col-span-6 pr-4 mb-6) xl:pr-6">
              <p>
                <span tw="font-bold">Patients were treated with</span> either{" "}
                <span tw="font-bold">LIVTENCITY</span> (400 mg,
                twice&nbsp;daily; 235 patients) or commonly used antiviral
                medicines (chosen by investigator; 117 patients){" "}
                <span tw="font-bold">for 8 weeks.</span> After completing the
                treatment period, patients entered a 12-week follow-up phase.
              </p>
            </div>
            <div tw="font-light col-span-full lg:col-span-10">
              <div tw="w-[60px] h-[2px] rounded-[2px] mb-6 bg-[linear-gradient(123deg, #7C45FF 40%, #40016D 97%)] lg:(mb-5 h-[3px] w-[80px]) xl:h-[4px]" />
              <p tw="font-normal italic text-orchid mb-4 w-3/4 lg:w-full">
                In adult patients with refractory or resistant CMV after
                transplant:
              </p>
              <p tw="mb-5">
                The main goal of the study was to evaluate how effective{" "}
                <span tw="font-bold">LIVTENCITY</span> was at helping patients
                reach a low level of CMV DNA compared to commonly used antiviral
                medicines after 8 weeks of treatment.
              </p>
            </div>
          </div>
          <p tw="font-bold mb-5 xl:(text-21 mt-5 mb-8)">
            Only patients 18 years of age and older were enrolled in the study.
            The recommended dosing of LIVTENCITY in children is based on the
            following:
          </p>
          <ul tw="list-disc ml-5 font-light lg:(columns-2 gap-14) xl:(text-21 gap-20 ml-6)">
            <li tw="mb-2">
              <span tw="relative left-2.5 lg:(left-1.5)">
                Clinical studies of adults taking LIVTENCITY
              </span>
            </li>
            <li tw="mb-2">
              <span tw="relative left-2.5 lg:(left-1.5)">
                Levels of LIVTENCITY in the blood are expected to be similar
                between adults and children 12 years of age weighing 77 pounds
              </span>
            </li>
            <li tw="mb-2 break-after-column">
              <span tw="relative left-2.5 lg:(left-1.5)">
                Studies showing that age and body weight had no meaningful
                effect on the levels of LIVTENCITY in the blood
              </span>
            </li>
            <li>
              <span tw="relative left-2.5 lg:(left-1.5)">
                The course of the disease is similar between adults and
                pediatric patients to allow the extension of data in adults to
                pediatric patients
              </span>
            </li>
          </ul>
          <p tw="font-bold mt-5 xl:(text-21)">
            The safety and effectiveness of LIVTENCITY have not been established
            in children under 12 years of age.
          </p>
        </Container>
      </section>

      <section>
        <Cta data={data} styles={tw`pb-10 lg:(pt-0 pb-14)`}>
          <div tw="relative top-[-400px] mb-[-350px] lg:(top-[-150px] -mb-24) xl:(top-[-200px])">
            <Container tw="mb-10">
              <div tw="relative py-12 px-4 ml-[-16px] w-[calc(100% + 32px)] rounded-[10px] [clip-path: polygon(30px 0, 100% 0, 100% calc(100% - 30px), calc(100% - 30px) 100%, 0 100%, 0 30px)] lg:(ml-0 pt-10 pb-8 w-full px-[50px] [clip-path: polygon(43px 0, 100% 0, 100% calc(100% - 43px), calc(100% - 43px) 100%, 0 100%, 0 43px)]) xl:([clip-path: polygon(60px 0, 100% 0, 100% calc(100% - 60px), calc(100% - 60px) 100%, 0 100%, 0 60px)])">
                <GatsbyImage
                  image={
                    width < 1024
                      ? getImage(data.smallPurpleBg)
                      : getImage(data.largePurpleBg)
                  }
                  tw="absolute w-full h-full top-0 left-0 z-[-1] rounded-[inherit]"
                  alt=""
                />
                <div tw="grid grid-cols-4 gap-x-7 lg:(grid-cols-12)">
                  {/* Left */}
                  <div tw="text-white col-span-full mb-8 lg:(mb-0 col-span-5 flex flex-col justify-center)">
                    <h2 tw="uppercase font-extrabold text-32 lg:(text-26) xl:text-4xl">
                      On Treatment
                    </h2>
                    <div tw="w-[110px] h-[4px] rounded-[2px] mt-4 mb-6 bg-coral lg:(mt-5 mb-6 h-[3px] w-[60px]) xl:(h-[4px])" />
                    <p tw="font-bold pr-5 text-lg leading-[25px] lg:(text-lg [line-height: 24px] mb-4) xl:(text-2xl)">
                      LIVTENCITY was shown to be superior:{" "}
                      <span tw="font-light">
                        More patients taking LIVTENCITY achieved a low level of
                        CMV DNA at Week 8 compared to patients who received
                        commonly used antiviral medicines
                      </span>
                    </p>
                  </div>
                  {/* Right */}
                  <div tw="flex gap-6 justify-between col-span-full md:(justify-around) lg:(col-start-7 col-span-6 gap-9 mr-8)">
                    <div tw="text-center bg-white pt-4 pb-2.5 px-1.5 rounded-md [clip-path: polygon(22px 0, 100% 0, 100% calc(100% - 22px), calc(100% - 22px) 100%, 0 100%, 0 22px)] md:(px-3) lg:(pt-5 pb-7) xl:([clip-path: polygon(32px 0, 100% 0, 100% calc(100% - 32px), calc(100% - 32px) 100%, 0 100%, 0 32px)])">
                      <GatsbyImage
                        image={getImage(data.stat1_1)}
                        alt="56%"
                        tw="mb-3 w-[70%] lg:(w-[80%] mb-6) xl:mb-8"
                      />
                      <p tw="text-xs xl:text-base [background-image: linear-gradient(90deg, #7C45FF 40%, #40016D 97%)] [background-clip: text] [-webkit-text-fill-color: transparent]">
                        <span tw="block font-bold text-base [line-height: 20px] lg:(font-extrabold text-[17px]) xl:(text-2xl)">
                          LIVTENCITY
                        </span>{" "}
                        (Percent of patients with a low level of CMV DNA)
                      </p>
                    </div>
                    <div tw="text-center bg-deepViolet pt-4 pb-2.5 px-1.5 rounded-md [clip-path: polygon(22px 0, 100% 0, 100% calc(100% - 22px), calc(100% - 22px) 100%, 0 100%, 0 22px)] md:(px-3) xl:([clip-path: polygon(32px 0, 100% 0, 100% calc(100% - 32px), calc(100% - 32px) 100%, 0 100%, 0 32px)])">
                      <GatsbyImage
                        image={getImage(data.stat1_2)}
                        tw="mb-1.5 w-[70%] lg:(w-[80%] mb-4)"
                        alt="24%"
                      />
                      <p tw="text-xs xl:text-base text-white">
                        <span tw="block font-bold text-base [line-height: 20px] lg:(font-extrabold px-2 text-[17px]) xl:(text-2xl)">
                          Other antiviral medicines
                        </span>{" "}
                        (Percent of patients with a low level of CMV DNA)
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </Container>
            <Container>
              <div tw="relative py-12 px-4 ml-[-16px] w-[calc(100% + 32px)] rounded-[10px] [clip-path: polygon(30px 0, 100% 0, 100% calc(100% - 30px), calc(100% - 30px) 100%, 0 100%, 0 30px)] lg:(ml-0 pt-10 pb-8 w-full px-[50px] [clip-path: polygon(43px 0, 100% 0, 100% calc(100% - 43px), calc(100% - 43px) 100%, 0 100%, 0 43px)]) xl:([clip-path: polygon(60px 0, 100% 0, 100% calc(100% - 60px), calc(100% - 60px) 100%, 0 100%, 0 60px)])">
                <GatsbyImage
                  image={
                    width < 1024
                      ? getImage(data.smallPurpleBg)
                      : getImage(data.largePurpleBg)
                  }
                  tw="absolute w-full h-full top-0 left-0 z-[-1] rounded-[inherit]"
                  alt=""
                />
                <div tw="grid grid-cols-4 gap-x-7 lg:(grid-cols-12)">
                  {/* Left */}
                  <div tw="order-2 flex gap-6 justify-between col-span-full md:(justify-around) lg:(order-1 col-span-6 gap-9 mr-8)">
                    <div tw="text-center bg-white pt-4 pb-2.5 px-1.5 rounded-md [clip-path: polygon(22px 0, 100% 0, 100% calc(100% - 22px), calc(100% - 22px) 100%, 0 100%, 0 22px)] md:(px-3) lg:(pt-5 pb-7) xl:([clip-path: polygon(32px 0, 100% 0, 100% calc(100% - 32px), calc(100% - 32px) 100%, 0 100%, 0 32px)])">
                      <GatsbyImage
                        image={getImage(data.stat2_1)}
                        alt="19%"
                        tw="mb-3 w-[70%] lg:(w-[80%] mb-6) xl:mb-8"
                      />
                      <p tw="text-xs xl:text-base [background-image: linear-gradient(90deg, #7C45FF 40%, #40016D 97%)] [background-clip: text] [-webkit-text-fill-color: transparent]">
                        <span tw="block font-bold text-base [line-height: 20px] lg:(font-extrabold text-[17px]) xl:(text-2xl)">
                          LIVTENCITY
                        </span>{" "}
                        (Percent of patients with a low level of CMV DNA)
                      </p>
                    </div>
                    <div tw="text-center bg-deepViolet pt-4 pb-2.5 px-1.5 rounded-md [clip-path: polygon(22px 0, 100% 0, 100% calc(100% - 22px), calc(100% - 22px) 100%, 0 100%, 0 22px)] md:(px-3) xl:([clip-path: polygon(32px 0, 100% 0, 100% calc(100% - 32px), calc(100% - 32px) 100%, 0 100%, 0 32px)])">
                      <GatsbyImage
                        image={getImage(data.stat2_2)}
                        tw="mb-1.5 w-[66%] lg:(w-[80%] mb-4)"
                        alt="10%"
                      />
                      <p tw="text-xs xl:text-base text-white">
                        <span tw="block font-bold text-base [line-height: 20px] lg:(font-extrabold px-2 text-[17px]) xl:(text-2xl)">
                          Other antiviral medicines
                        </span>{" "}
                        (Percent of patients with a low level of CMV DNA)
                      </p>
                    </div>
                  </div>
                  {/* Right */}
                  <div tw="order-1 text-white col-span-full mb-8 lg:(order-2 mb-0 col-start-7 col-span-6 flex flex-col justify-center)">
                    <h2 tw="uppercase font-extrabold text-32 lg:(text-26) xl:text-4xl">
                      After Treatment
                    </h2>
                    <div tw="w-[110px] h-[4px] rounded-[2px] mt-4 mb-6 bg-coral lg:(mt-5 mb-6 h-[3px] w-[60px]) xl:(h-[4px])" />
                    <p tw="font-bold pr-5 text-lg leading-[25px] lg:(text-lg [line-height: 24px] mb-4) xl:(text-2xl)">
                      A greater percentage of patients who received LIVTENCITY
                      maintained a low level of CMV DNA{" "}
                      <span tw="font-light">
                        along with control of symptoms at week 16 compared to
                        patients who received commonly used antiviral medicines
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </Container>
          </div>

          <Container>
            <div tw="grid grid-cols-4 gap-x-7 md:(grid-cols-12)">
              {/* Image */}
              <div tw="col-span-full px-12 mb-6 md:(col-span-5 pr-0 pl-0 mb-0) lg:(pr-8)">
                <GatsbyImage image={getImage(data.ctaImage)} alt="" />
              </div>
              {/* Content */}
              <div tw="col-span-full md:(col-start-7 col-span-6 flex flex-col justify-center -ml-8) lg:(ml-0)">
                <p tw="font-extrabold text-[28px] [line-height: 30px] mb-8 md:(font-bold text-xl mb-4) xl:(text-27)">
                  Always discuss medical questions with your transplant team.
                </p>
                <p tw="font-light mb-6 md:(text-base mb-4) xl:(text-xl mb-6)">
                  If you need help speaking to them about CMV or{" "}
                  <span tw="font-bold">LIVTENCITY</span>, check out{" "}
                  <a
                    href="/pdf/tips-for-talking-with-your-transplant-team.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                    tw="font-bold underline"
                    className="gtm-download"
                    data-documentname="DoctorDiscussionGuide"
                  >
                    Tips for Talking With Your Transplant Team
                  </a>{" "}
                  below.
                </p>
                <div tw="text-center mb-10 md:(text-left mb-10) xl:(mb-14)">
                  <Button
                    link="/pdf/tips-for-talking-with-your-transplant-team.pdf"
                    external
                    buttonClass="gtm-download"
                    documentName="DoctorDiscussionGuide"
                  >
                    Download Now
                  </Button>
                </div>
                <p tw="font-extrabold text-[28px] [line-height: 30px] mb-6 md:(font-bold text-xl mb-4) xl:(text-27 mb-6)">
                  Knowing how to take LIVTENCITY is important.
                </p>
                <div tw="text-center md:(text-left)">
                  <Button link="/dosing/" buttonClass="gtm-cta-nav">
                    Ready To Explore Dosing?
                  </Button>
                </div>
              </div>
            </div>
          </Container>
        </Cta>
      </section>
    </Layout>
  )
}

export const Head = () => (
  <Seo
    title="What is LIVTENCITY® (maribavir)?"
    description="Learn about LIVTENCITY prescription medicine including what it is, who it is for, and its efficacy and safety alongside commonly used antivirals. See full Safety and Patient Information."
  />
)

export default WhatIsLivtencity
