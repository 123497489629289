import React from "react"
import tw from "twin.macro"
import { ImageQuery } from "./imageQuery"
import PropTypes from "prop-types"

const CtaWrapper = tw.div`relative overflow-x-clip pt-12 pb-5 min-h-[350px]`

const Cta = ({ children, data, position, styles }) => {
  return (
    <CtaWrapper css={[styles && styles]}>
      <ImageQuery
        alt=""
        data={data}
        objectFit="cover"
        objectPosition={position}
        style={{
          width: "100%",
          borderRadius: "inherit",
          height: "100%",
          position: "absolute",
          top: 0,
          left: 0,
          zIndex: "-1",
          pointerEvents: "none",
        }}
      />
      {children}
    </CtaWrapper>
  )
}

export default Cta

Cta.propTypes = {
  children: PropTypes.node.isRequired,
  position: PropTypes.string,
  styles: PropTypes.object,
}

Cta.defaultProps = {
  position: "left bottom",
}
